@use 'styles/includes' as *;

.Breadcrumbs {
    $root: &;

    margin: 0 0 0.5rem;
    font-family: $font-family-regular;
    font-size: 1.4rem;
    line-height: normal;
    color: $white;

    @include media(l) {
        font-size: 2rem;
    }

    &__Page {
        &::after {
            content: ' / ';
        }
    }

    &__Link {
        @media (hover: hover) {
            &:hover {
                text-decoration: underline;
                text-underline-offset: 2px;
            }
        }
    }
}
